import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userDetails",

  initialState: {
    userData: {
      name: "",
      phoneNumber: "",
      mobileNumber:"",
      panNumber: "",
      dob: "",
      kycTypeSelected: "",
      totalKyc:0,
      limit:5
    },
    geoLocation: {
      location: {},
    },
    customerData: {
      gender: "",
      maritalStatus: "",
      mothersName: "",
      correspondenceAddress: "",
      correspondenceState: "",
      correspondenceCity: "",
      correspondencePincode: "",
      userEditedAddress: false,
      userEditedName: false,
      name: "",
      permanentAddress: "",
      doi:"",
      permanentCity: "",
      permanentDistrict: "",
      permanentCountry: "",
      permanentState: "",
      permanentPincode: "",
      isAadhaarMasked: false,
      fatherName: "",
      // correspondence
    },
  },
  reducers: {
    // userData: (state, action) => {
    //   state.userData = { ...state.userData, ...action.payload };
    // },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    userLocation: (state, action) => {
      state.geoLocation = action.payload;
    },
    customerData: (state, action) => {
      state.customerData = { ...state.customerData, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { userData, userLocation, customerData, updateUserData } =
  userSlice.actions;

export default userSlice.reducer;
