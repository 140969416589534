import { encrypt } from "../utils/encryptionV2";
import { apiInstance } from "./networking";

export const marutiUrl = async (payload) => {
  const { data = {} } = await apiInstance.post(`util/login/maruti`, {enc_request:encrypt(payload)}, {
    headers: {
      api_key: process.env.REACT_APP_MARUTI_API_KEY,
    },
  });
  return data;
};
