import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Otherdocsdropdown from "../Screens/KYCHomeScreen/Frames/Otherdocsdropdown";
import { useDispatch, useSelector } from "react-redux";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fileUpload,
  formSixtyDocument,
  formSixtyDocumentName,
  previewIamgeURL,
} from "../Screens/Redux/otherDocsSlice";
import { allowedFileSizeMb, gaFunction, scrollIntoViewFunc } from "../Router";
import { useLocation, useNavigate } from "react-router-dom";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { userPanNumber } from "../Screens/Redux/panCardSlice";
import { toggleButton } from "../Screens/Redux/switchToggleSlice";
import CommonButton from "../Components/global-components/CommonButton/CommonButton";
import TextInput from "../Components/global-components/CommonTextInput/TextInput";
import ErrorBottomDrawer from "../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";
import { activeStepper } from "../Screens/Redux/stepperSlice";
import { optionsValue } from "../Screens/Redux/otherdocsDropDownValueSlice";
import MsHome from "../Components/Maruti-Suzuki-components/MsHome";
import BannerImage from "../Components/login-components/banner-image/BannerImage";
import LogoContainer from "../Components/login-components/logo-container/LogoContainer";
import BottomCard from "../Components/login-components/bottom-card/BottomCard";
import {
  coIndividualPanNumber,
  kycType,
} from "../Screens/Redux/corporateSlice";
import CorporateUploadFile from "./CorporateUploadFile";
import Loadingoverlay from "../Components/global-components/LoadingOverlay/Loadingoverlay";

const CorporateAuthorisedDocument = ({ isMaruti = false }) => {
  const userDetails = useSelector((state) => state.userDetails.userData);
  const { panNumber: reduxPanNumber } = userDetails;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [panNumber, setPanNumber] = useState(reduxPanNumber);
  const [switchState, setSwitchState] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [panValidationError, setpanValidationError] = useState(null);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(true);
  const [isDeclarationCheckedError, setIsDeclarationCheckedError] =
    useState("");
  const [isMaskedAadhaarDrawerOpen, setIsMaskedAadhaarDrawerOpen] =
    useState(false);
  const [isFormSixtyConfirmBox, setIsFormSixtyConfirmBox] = useState(false);
  const [errorFormSixty, setErrorFormSixty] = useState("");
  const istoggle = useSelector((state) => state.switchButton.activeSwitch);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isRouteAllowed } = state || {};
  const formSixty = useSelector(
    (state) => state.uploadedFile.formSixtyDocument
  );
  const formSixtyDocName = useSelector(
    (state) => state.uploadedFile.formSixtyDocumentName
  );
  const frontImageUrl = useSelector(
    (state) => state.uploadedFile.previewImage.frontImageURL
  );

  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );

  const otherDocsDropDownValue = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  //upload document function
  const uploadDrawerHandler = () => {
    gaFunction("OVD", "OVD upload clicked");
    if (!istoggle) {
      if (!formSixty?.length) {
        scrollIntoViewFunc("form_sixty");
        return setErrorFormSixty("Please upload form 60 pdf");
      }
    }
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    if (!regexPan.test(panNumber) && istoggle) {
      // setIsDrawerOpen((prev) => prev);
    } else {
      setIsDrawerOpen((prev) => !prev);
      if (otherDocsDropDownValue === "maskedAadhaar") {
        setIsMaskedAadhaarDrawerOpen(true);
        setIsDrawerOpen(false);
      }
    }
    if (panNumber?.length !== 10) {
      scrollIntoViewFunc("ovd_pan_on");
      setErrorMessage("Please provide your Pan Number");
    }
  };

  // pan input function
  const panChangeHandler = (value) => {
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    value=value?.replace(/\s/g,"");
    if (value.length <= 10) {
      setPanNumber(value);
      dispatch(coIndividualPanNumber(value));
    }
    setpanValidationError(null);
    if (!regexPan.test(value)) {
      setpanValidationError("Enter valid PAN Number");
    }
    if (panNumber?.length >= 0) {
      setErrorMessage(null);
    }
  };

  //upload form 60 handler
  const uploadFormSixtyDrawerHandler = () => {
    gaFunction("OVD", "OVD upload clicked");
    setIsFormSixtyConfirmBox(true);
  };

  // pan switch handler
  const toggleHandler = (e) => {
    dispatch(toggleButton(e.target.checked));
  };
  const deleteFormSixtyHandler = () => {
    dispatch(formSixtyDocument(""));
    dispatch(formSixtyDocumentName(""));
  };

  useEffect(() => {
    if (formSixty?.length) setErrorFormSixty("");
  }, [formSixty]);

  // useEffect(() => {
  //   if (istoggle && frontImageUrl.length && isDeclarationChecked) {
  //     setIsDeclarationCheckedError("");
  //     navigate("/uploadDocument");
  //   }
  //   if (
  //     !istoggle &&
  //     formSixty.length &&
  //     frontImageUrl.length &&
  //     isDeclarationChecked
  //   ) {
  //     setIsDeclarationCheckedError("");
  //     navigate("/uploadDocument");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formSixty, frontImageUrl, isDeclarationChecked, istoggle]);

  useEffect(() => {
    if (istoggle && frontImageUrl.length) {
      setIsDeclarationCheckedError("Please accept declaration");
      scrollIntoViewFunc("ovd_dec_checkbox");
    }
    if (!istoggle && formSixty.length && frontImageUrl.length) {
      setIsDeclarationCheckedError("Please accept declaration");
      scrollIntoViewFunc("ovd_dec_checkbox");
    }
  }, [formSixty, frontImageUrl, istoggle]);

  useEffect(() => {
    //reset all ovd data on mount
    dispatch(formSixtyDocument(""));
    dispatch(formSixtyDocumentName(""));
    dispatch(fileUpload({ frontFile: "" }));
    dispatch(
      previewIamgeURL({
        frontImageURL: "",
      })
    );
    dispatch(optionsValue("aadhaar"));
    setIsDeclarationCheckedError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let reduxPanValue=reduxPanNumber;
    reduxPanValue=reduxPanValue?.replace(/\s/g,"");
    if (reduxPanValue?.length == 10) {
      setPanNumber(reduxPanValue);
      dispatch(userPanNumber(reduxPanValue));
    }else{
      setPanNumber("");
    }
  }, [reduxPanNumber]);
  useEffect(() => {
    dispatch(activeStepper(2));
    dispatch(kycType("corporate"));
  }, []);

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);
  useEffect(() => {
    if (!state) navigate("/login");
  }, [navigate, state]);

  return (
    <>
      {isRouteAllowed && (
        <MsHome>
          <BannerImage>
            <LogoContainer />
          </BannerImage>
          <div className="bg-[#FCE9E9] h-full">
            <BottomCard>
              <div className={`h-full w-full md:mt-["6px"]`}>
                <div className="flex   gap-[10px]  items-center mt-4 md:h-[8%]">
                  <p>Do you have a valid PAN Number?</p>
                  <label
                    htmlFor="green-toggle"
                    className="inline-flex relative items-center mr-5 md:mr-0 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="green-toggle"
                      className="sr-only peer"
                      onChange={toggleHandler}
                      checked={istoggle}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-white-700 peer-focus:ring-4   peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 focus:outline-none ring ring-0"></div>
                  </label>
                </div>
                <div className="md:h-[65%]  overflow-scroll mt-2">
                  <div className={`mt-[${isMaruti ? "0" : "0px"}]`}></div>
                  <div>
                    {istoggle === false ? (
                      <div>
                        <div>
                          <a
                            href="/assets/Form60.pdf"
                            target="_blank"
                            className="text-md"
                          >
                            Click <u className="text-blue-600">here</u> to
                            download Form 60,
                          </a>
                          <span className="text-md">
                            &nbsp;Fill details and upload below
                          </span>
                        </div>

                        <div className="border-dashed border-2 border-gray-300 p-4 md:w-[95%]">
                          <div className="text-center ">
                            {formSixty.length > 0 ? (
                              <>
                                <p className="font-bold lato-famliy">
                                  File Uploded Successfully
                                </p>
                                <p className="mt-2">
                                  <FileCopyIcon />
                                  {formSixtyDocName}
                                </p>
                              </>
                            ) : (
                              <>
                                <FileUploadIcon />
                                <p className="font-bold mt-2 " id="form_sixty">
                                  Select a pdf file to upload
                                </p>
                                <p className="text-sm mb-1">
                                  Allowed file size is {allowedFileSizeMb} MB
                                </p>
                                <p className="opacity-50 text-xs">
                                  Supported file format is pdf only
                                </p>
                              </>
                            )}
                          </div>
                          <div className="p-2"></div>
                          <div className=" flex justify-center">
                            <div className="w-32">
                              {isForm && (
                                <CorporateUploadFile
                                  onClose={setIsForm}
                                  isFormSixty={true}
                                  acceptFileType="application/pdf"
                                />
                              )}
                              {formSixty.length > 0 ? (
                                <div className="flex justify-around">
                                  <CheckCircleIcon
                                    fontSize="large"
                                    className="text-green-500 "
                                  />
                                  <DeleteIcon
                                    fontSize="large"
                                    className="text-red-500"
                                    onClick={deleteFormSixtyHandler}
                                  />
                                </div>
                              ) : (
                                <CommonButton
                                  center
                                  label={"Upload"}
                                  onClickHandler={uploadFormSixtyDrawerHandler}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {errorFormSixty && (
                          <p className="mt-2 text-red-500 text-md">
                            {errorFormSixty}
                          </p>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className={`mt-${isMaruti ? "0" : ""}`}>
                          {istoggle ? (
                            <div className="md:w-[65%]" id="ovd_pan_on">
                              <label
                                htmlFor="first_name"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700"
                              >
                                PAN No.
                              </label>
                              <TextInput
                                value={panNumber}
                                id="otherdocs_panNumber"
                                onChange={(e) => {
                                  panChangeHandler(
                                    e.target.value.toUpperCase()
                                  );
                                }}
                                placeholder="PAN number"
                                required
                              />
                              {panValidationError && (
                                <p className="text-red-500 text-sm">
                                  {panValidationError}
                                </p>
                              )}
                              {errorMessage && istoggle === true && (
                                <p className="text-red-500 font-medium">
                                  {errorMessage}
                                </p>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div>
                                <a
                                  href="/assets/Form60.pdf"
                                  target="_blank"
                                  className="text-md"
                                >
                                  Click <u className="text-blue-600">here</u> to
                                  download Form 60,
                                </a>
                                <span className="text-md">
                                  &nbsp;Fill details and upload below
                                </span>
                              </div>
                              <div className="border-dashed border-2 border-gray-300 p-4">
                                <div className="text-center ">
                                  {formSixty.length > 0 ? (
                                    <>
                                      <p className="font-bold lato-famliy">
                                        File Uploded Successfully
                                      </p>
                                      <p className="mt-2">
                                        <FileCopyIcon />
                                        {formSixtyDocName}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <FileUploadIcon />
                                      <p
                                        className="font-bold mt-2 "
                                        id="form_sixty"
                                      >
                                        Select a pdf file to upload
                                      </p>
                                      <p className="text-sm mb-1">
                                        Allowed file size is {allowedFileSizeMb}{" "}
                                        MB
                                      </p>
                                      <p className="opacity-50 text-xs">
                                        Supported file format is pdf only
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="p-2"></div>
                                <div className=" flex justify-center">
                                  <div className="w-32 ">
                                    {isForm && (
                                      <CorporateUploadFile
                                        onClose={setIsForm}
                                        isFormSixty={true}
                                        acceptFileType="application/pdf"
                                      />
                                    )}
                                    {formSixty.length > 0 ? (
                                      <div className="flex justify-center">
                                        <CheckCircleIcon
                                          fontSize="large"
                                          className="text-green-500"
                                        />
                                      </div>
                                    ) : (
                                      <CommonButton
                                        label={"Upload"}
                                        id={"upload_docs_btn"}
                                        onClickHandler={
                                          uploadFormSixtyDrawerHandler
                                        }
                                        center
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {errorFormSixty && (
                                <p className="mt-2 text-red-500 text-md">
                                  {errorFormSixty}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="p-3"></div>
                  <div className="border-dashed border-2 border-gray-300 p-4 md:w-[95%] w-full">
                    <div className="text-center short:h-[10%]">
                      <FileUploadIcon />
                      <p className="font-bold mt-2">Select a file to upload</p>
                      <p className="text-sm mb-1">
                        Allowed file size is {allowedFileSizeMb} MB
                      </p>
                      <p className="opacity-50 text-xs">
                        Supported file formats are png, jpeg, and jpg.
                      </p>
                      {frontFileName?.length > 0 && (
                        <p className="mt-2">
                          <FileCopyIcon />
                          {frontFileName}
                        </p>
                      )}
                    </div>
                    <div className="p-2"></div>
                    <div className="flex items-center justify-between gap-10">
                      <div className="w-[60%]">
                        <Otherdocsdropdown />
                        {isMaskedAadhaarDrawerOpen && (
                          <ErrorBottomDrawer
                            onClickHandler={() => {
                              setIsMaskedAadhaarDrawerOpen(false);
                              setIsDrawerOpen(true);
                            }}
                            info
                            errorMessage={
                              "Kindly upload masked image of aadhaar only."
                            }
                          />
                        )}
                      </div>
                      <div className="w-[40%] flex">
                        {isDrawerOpen && (
                          <CorporateUploadFile
                            onClose={setIsDrawerOpen}
                            isNavigate={isDeclarationChecked}
                          />
                        )}
                        <CommonButton
                          label={
                            frontFileName?.length ? "File uploaded" : "Upload"
                          }
                          onClickHandler={uploadDrawerHandler}
                          center
                          id={"upload_btn"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-4" id="ovd_dec_checkbox">
                    {isDeclarationCheckedError && (
                      <div className="mb-1 text-red-500 text-md">
                        {isDeclarationCheckedError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-2 md:p-0"></div>
                <footer className="md:h-[20%] text-sm">
                  <p>Acceptable Documents (Any one) :</p>
                  <div className="flex flex-row gap-10">
                    <div className="flex flex-col text-xs">
                      <li>Aadhaar card / masked aadhaar </li>
                      <li>Passport</li>
                    </div>
                    <div className="flex flex-col text-xs">
                      <li>Driving License</li>
                      <li>Voters’ ID Card</li>
                    </div>
                  </div>
                  <div className="p-2"></div>
                  <p className="text-sm md:hidden mb-3 ">
                    <b>
                      *Kindly keep the original document handy to be used for
                      verification in the next step.*
                    </b>
                  </p>
                </footer>
              </div>
              <Dialog
                open={isFormSixtyConfirmBox}
                onClose={() => {
                  setIsForm(false);
                  setIsFormSixtyConfirmBox(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Have you filled the form correctly?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setIsFormSixtyConfirmBox(false)}
                    color="error"
                    id="alert-ovd-no-button"
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      setIsFormSixtyConfirmBox(false);
                      setIsForm(true);
                    }}
                    color="success"
                    autoFocus
                    id="alert-ovd-yes-button"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </BottomCard>
          </div>
        </MsHome>
      )}
    </>
  );
};

export default CorporateAuthorisedDocument;
