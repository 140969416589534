import { createSlice } from "@reduxjs/toolkit";
export const reason = createSlice({
  name: "exceptionReason",
  initialState: {
    reason: "",
    panTypeError:false
  },
  reducers: {
    setReasonText: (state, action) => {
      state.reason = action.payload;
    },
    setPantypeError:(state,action)=>{
      state.panTypeError=action.payload
    }
  },
});
// Action creators are generated for each case reducer function
export const { setReasonText,setPantypeError } = reason.actions;
export default reason.reducer;