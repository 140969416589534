import { combineReducers } from "redux";
import Tabs from "./slice";
import switchButton from "./switchToggleSlice";
import userDetails from "./userData";
import kycDetails from "./kycDetailsSlice";
import panDetails from "./panCardSlice";
import uploadedFile from "./otherDocsSlice";
import AdhaarRedirectInfo from "./AdhaarRedirectInfoSlice";
import dropDownValue from "./otherdocsDropDownValueSlice";
import CameraImage from "./CameraSlice";
import voterIdDetails from "./voterIdDetails";
import authSlice from "./authSlice";
import stepperSlice from "./stepperSlice";
import partnerChannelNameSlice from "./partnerChannelNameSlice";
import globalErrorSlice from "./globalErrorSlice";
import digitalFormSixty from "./digitalFormSixtySlice"
import corporateSlice from "./corporateSlice";
import corporatePreloadData from "./corporatePreloadData";
import ExceptionReasonSlice from "./ExceptionReasonSlice";
const reducers = combineReducers({
  Tabs,
  switchButton,
  userDetails,
  kycDetails,
  uploadedFile,
  panDetails,
  AdhaarRedirectInfo,
  dropDownValue,
  CameraImage,
  voterIdDetails,
  authSlice,
  stepperSlice,
  partnerChannelNameSlice,
  globalError: globalErrorSlice,
  digitalFormSixty,
  corporateSlice,
  corporatePreloadData,
  ExceptionReasonSlice
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};
