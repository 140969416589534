import React from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CommonButton from "./CommonButton/CommonButton";
import { BsInfoCircleFill } from "react-icons/bs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "white",
  outline: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};
const style1 = {
  position: "fixed",
  // top: "inherrit !important",
  // top: "50%",
  bottom: 0,
  left: "50%",
  // transform: "translate(-50%, -50%)",
  transform: "translateX(-50%)",
  width: "80%",
  bgcolor: "white",
  outline: "none",
  boxShadow: 24,
  borderRadius: "30px",
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "0px",
  p: 4,

  "@media (min-width: 610px)":{
    width: "60%",
  },
  "@media (min-width: 1024px)":{
    width: "50%",
  },
};
const style3 = {
  width: "100% !important",
  // top:"inherit !important",
  bottom:"0px !important",
  maxHeight: "90vh",
  margin:"auto !inherit",
};
const iconColor ={
  color:"#e94d51 !important",
  fill:"#e94d51"
}


const CommonTemplate = ({
  isTemplateOpen = true,
  closeTemplate,
  okTemplate,
  modalLabel,
  modalText,
  submitTemplate,
  backTemplate,
  children,
  userDetails,
  kycType
}) => {
  return (
    <div>
      <>
      {!children ? 
      <Modal
        open={isTemplateOpen}
        onClose={closeTemplate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="flex flex-row items-center gap-2">
            <BsInfoCircleFill  className="info_icon"  /> {
            !children ? (<span className="lato-family">Notice</span>)
            :
          <span className="lato-family  font-semibold ">{modalLabel}</span>
            }
            
          </div> 
          <Typography
            id="modal-modal-title"
            sx={{ fontWeight: "bold", mt: 2 }}
            className="lato-family"
          >
            {modalLabel}
            {
            !children ? (  {modalLabel}):""}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="lato-family"
          >
            {modalText}
          </Typography>
          {children}
          <div className="flex  text-center justify-between gap-10 md:w-[235px] md:m-auto pt-4 pb-2">
          {/* <CommonButton
              center
              label="Change PAN"
              filled
              onClickHandler={backTemplate}
              
            /> */}
          <CommonButton
              center
              label="Proceed"
              filled
              onClickHandler={submitTemplate}
              
            />
            {/* <CommonButton
              center
              label="Close"
              filled
              onClickHandler={closeTemplate}
            /> */}
          </div>
        </Box>
      </Modal>
      :<Modal
      open={isTemplateOpen}
      onClose={closeTemplate}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={style3}
      >
      <Box sx={style1}>
                 
                 <div className="flex flex-row items-center gap-2">
                 <BsInfoCircleFill className="info_icon" /> 
                 {
                 !children ? (<span className="lato-family">Notice</span>)
                 :
               <span className="lato-family  font-semibold">{modalLabel}</span>
                 }
                 <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
               onClick={closeTemplate}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              
            </button>
               </div> 
               
               <Typography
                 id="modal-modal-title"
                 sx={{ fontWeight: "bold", mt: 2 }}
                 className="lato-family"
               >
               {
                 !children ? (  {modalLabel}):""}
               </Typography>
               
               
               <Typography
                 id="modal-modal-description"
                 sx={{ mt: 2 }}
                 className="lato-family"
               >
                 {modalText}
               </Typography>
               {children}
               <div className="flex  text-center justify-between gap-10 md:w-[235px] md:m-auto pt-4 pb-2 ">
                { (userDetails || kycType == "Individual") ? 
                <CommonButton
                center
                label="Okay, Understood"
                filled
                onClickHandler={closeTemplate}
                
              /> :
                <>
               {/* <CommonButton
                   center
                   label="Change PAN"
                   filled
                   onClickHandler={backTemplate}
                   
                 /> */}
               <CommonButton
                   center
                   label="Proceed"
                   filled
                   onClickHandler={submitTemplate}
                   
                 />
                 {/* <CommonButton
                   center
                   label="Close"
                   filled
                   onClickHandler={closeTemplate}
                   
                 /> */}
                 </>
                }
                 
                 
               </div>
               
             </Box>
           </Modal>
      }
            </>
    </div>
  );
};

export default CommonTemplate;
