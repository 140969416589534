import { apiInstance } from "./networking";

export const getOtpV2 = async (payload) => {
  const { data = {} } = await apiInstance.post(`/V2/util/sendOtp`, { enc_request: payload }, {
    headers: {
      api_key: process.env.REACT_APP_API_KEY,
    },
    proxy: {
      host: 'https://pehchaanuat.hdfcergo.com',
    }
  });
  return data;
};

export const getOtp = async (payload) => {
  const { data = {} } = await apiInstance.post(`/util/sendOtp`, payload, {
    headers: {
      api_key: process.env.REACT_APP_API_KEY,
    },
  });
  return data;
};

export const verifyOtp = async (payload) => {
  const { data = {} } = await apiInstance.post(`util/verifyOtp`, payload, {
    headers: {
      api_key: process.env.REACT_APP_API_KEY,
    },
  });
  return data;
};

export const verifyOtpV2 = async (payload) => {
  const { data = {} } = await apiInstance.post(`/V2/util/verifyOtp`, {enc_request:payload}, {
    headers: {
      api_key: process.env.REACT_APP_API_KEY,
    },
  });
  return data;
};
