import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styles from "./Drawer.module.css";
import CommonButton from "../CommonButton/CommonButton";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      "@media (min-width: 500px)": {
        width: "50%",
        margin: "auto",
      },
    },
  },
});

const CommonDrawer = ({ onProceed, onDenied , warnMsg }) => {
  // let panUserName = useSelector((state) => state.panDetails.panUserdata);
  const toogleButton = useSelector((state) => state.switchButton.activeSwitch);
  const activeTabs = useSelector((state) => state.Tabs.activeTab);

  // let voterIdDetails = useSelector((state) => state.voterId.voterDetails);

  const classes = useStyles();
  const [state] = useState({
    bottom: true,
  });

  const { name } = useSelector((state) => state.userDetails.userData);

  const list = (anchor) => (
    <Box
      className={styles["drawer"]}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
    >
      <div className="flex items-center gap-2 border-bottom">
        {<ErrorOutlineIcon sx={{ color: "red" }} />}
        <ListItemText primary={"Important Note"} />
      </div>
      <div className="p-[5px]"></div>
      {name == "" ? (
        <p className="md:text-center">
          We are unable to fetch your data. Please enter your details to
          proceed.
        </p>
      ) : (
        <p className="md:text-center">
          {`The name fetched from  ${
            activeTabs === "pan"
              ? "PAN"
              : activeTabs === "aadhaar"
              ? "AADHAAR"
              : "OVD"
          } is `}
          &nbsp;
          <span className="capitalize">{name}</span> . Your KYC and policy will
          be processed using this name.
          <p> {activeTabs === "pan" && 'Note: Only one successful hit is permitted per session'} </p>
          {/* We are unable to fetch your data. Please enter your details to proceed. */}
        </p>
      )}
      {warnMsg && (
        <p className="md:text-center">
          Please manually enter your address as we were unable to retrieve it.
        </p>
      )}
      <div className="p-[5px]"></div>
      {/* <p>Do you wish to continue?</p> */}
      <div className="p-[5px]"></div>
      <div className="flex  text-center justify-between gap-10 md:w-[235px] md:m-auto">
        {name == "" ? (
          <CommonButton
            id="drawer-yes-button"
            label={"proceed"}
            center
            filled
            onClickHandler={onProceed}
          />
        ) : (
          <>
            <CommonButton
              label={"Yes"}
              filled
              center
              onClickHandler={onProceed}
            />
            <CommonButton
              id="drawer-no-button"
              label={"No"}
              center
              onClickHandler={onDenied}
            />
          </>
        )}
        {/* <CommonButton label={"No"} center onClickHandler={onClickHandler} /> */}
        {/* <CommonButton
          id="drawer-no-button"
          label={"No"}
          center
          onClickHandler={onDenied}
        /> */}
        {/* <CommonButton
          id="drawer-yes-button"
          label={"proceed"}
          center
          filled
          onClickHandler={onProceed}
        /> */}
      </div>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor, index) => (
        <React.Fragment key={Math.random() + index}>
          <Drawer anchor={anchor} open={state[anchor]} classes={classes}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommonDrawer;
