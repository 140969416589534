function checkPanNumber(companytype, panNumber) {
    const expectedLetters = {
        "Company Ltd": ["C"],
        "Partnership Firm": ["F"],
        "Trust": ["C", "T"],
        "Unincorporated Institution": ["A", "B"],
        "Proprietorship": ["P"],
        "HUF": ["H"],
        "LLP": ["F"],
        "Society or Educational Institution": ["T", "C"],
        "Government Entity": ["G", "L", "J"],
        "Foreign Entity": ["C"],
        "Local Authority": ["L"],
        "Artificial Juridical Persons": ["J"],
    };
    // const companyTypeMessage = {
    //     "Company Ltd": " 'C'",
    //     "Partnership Firm": " 'F'",
    //     "Trust": " 'C' or 'T'",
    //     "Unincorporated Institution": " 'A' or 'B'",
    //     "Proprietorship": " 'P'",
    //     "HUF": " 'H'",
    //     "LLP": " 'L'",
    //     "Society or Educational Institution": " 'T' or 'C'",
    //     "Government Entity": " 'G' or 'L' or 'J'"
    // };
    if (companytype in expectedLetters && !expectedLetters[companytype].includes(panNumber[3])) {
        return `You have selected the entity type as " ${companytype} " but PAN entered is not of " ${companytype} " entity. If this is not an error and you still want to proceed with the same PAN, please provide a reason.`
    }
    else return ""
}
export default checkPanNumber;