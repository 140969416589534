import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import CommonDropDown from "../Components/global-components/CommonDropDown/CommonDropDown";
import ErrorBottomDrawer from "../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer"
import BannerImage from "../Components/login-components/banner-image/BannerImage";
import BottomCard from "../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../Components/login-components/logo-container/LogoContainer";
import MsHome from "../Components/Maruti-Suzuki-components/MsHome";
import TextInput from "../Components/global-components/CommonTextInput/TextInput";
import CommonButton from "../Components/global-components/CommonButton/CommonButton";
import ToggleButton from "../Components/kyc-components/ToggleButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  coAddNewKycIdReducer,
  coCinNumber,
  coCkycNumber,
  coGstNumber,
  coErrorMessageReducer,
  coFileUpload,
  coPanUserdataReducer,
  coRadioValue,
  corporateAnotherMode,
  corporateBackendValue,
  corporateDropDownValue,
  coUserDataReducer,
  coUserDoiReducer,
  corporateCompanyType
} from "../Screens/Redux/corporateSlice";
import { toggleButton } from "../Screens/Redux/switchToggleSlice";

import { activeStepper } from "../Screens/Redux/stepperSlice";
import { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { formValidator, scrollIntoViewFunc } from "../Router";
import { getPanCardDetails, getPanCardDetailsV2 } from "../services/panCard-service";
import { panUserdata } from "../Screens/Redux/panCardSlice";
import Loadingoverlay from "../Components/global-components/LoadingOverlay/Loadingoverlay";

import { coCinService,coGstService, coPanService } from "../services/corporate-ckyc-service";

import { showGlobalError } from "../Screens/Redux/globalErrorSlice";
import checkPanNumber from "../utils/checkPanNumber"
import { setPantypeError, setReasonText } from "../Screens/Redux/ExceptionReasonSlice";
import { coProprietorRadioValue, coTrustRadioValue } from "../Screens/Redux/corporateSlice";
import { encrypt,decrypt } from "../utils/encryptionV2";
import { coIsAdminCheckReason } from "../services/corporate-admin-check-reason";
import CommonTemplate from "../Components/global-components/CommonModal";

// CAPTCHA SERVICE
import {getCaptcha} from "../services/captcha-service"   
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";


const companyType = [
  "Select",
  "Company Ltd",
  "Partnership Firm",
  "Trust",
  "Unincorporated Institution",
  "Proprietorship",
  "HUF",
  "LLP",
  "Society or Educational Institution",
  "Government Entity",
  "Foreign Embassy"
];

const companyTypeMaruti = [
  "Select",
  "Company Ltd",
  "Partnership Firm",
  "Trust",
  "Unincorporated Institution",
  "HUF",
  "LLP",
  "Society or Educational Institution",
  "Government Entity",
];

const storeValueMatcher = [
  { label: "Company Ltd", value: "company" },
  { label: "Partnership Firm", value: "partnershipFirm" },
  { label: "Trust", value: "trust" },
  { label: "Unincorporated Institution", value: "unincorporatedInstitution" },
  { label: "Proprietorship", value: "properietor" },
  { label: "HUF", value: "huf" },
  { label: "LLP", value: "llp" },
  {
    label: "Society or Educational Institution",
    value: "societyOrEducationalInstitute",
  },
  { label: "Government Entity", value: "governmentEntity" },
  { label: "Foreign Embassy", value: "foreignEmbassy" }
];

const CorporatePanCardLanding = () => {
  const companyTypeRedux = useSelector((state) => state.corporateSlice.companyType);
  const [getCompanyType, setGetCompanyType] = useState(companyTypeRedux || "");
  const { pan, cin, ckyc, doi , gstNumber} = useSelector(
    (state) => state.corporatePreloadData.preloadData
  );
  const [radioValue, setRadioValue] = useState(
    (pan && "Pan") || (cin && "Cin") || (ckyc && "Ckyc") || (gstNumber && "GST Number") || "Pan"
  );

  const [proprietorRadioValue, setProprietorRadioValue] =
    useState("Proprietor");

  const [trustRadioValue , setTrustRadioValue] = useState("");

  const istoggle = useSelector((state) => state.switchButton.activeSwitch);


  const [panOrCkycOrCinValue, setpanOrCkycOrCinValue] = useState(
    pan || cin || ckyc || gstNumber || ""
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [dateOfIncorporationValue, setDateOfIncorporationValue] = useState(
    doi || ""
  );
  const [panValidationError, setpanValidationError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(true);

  const [gstLength , setGstLength] = useState(true);
  const [errorMsg,setErrorMsg]=useState(false)
  const [open, setOpen] = useState(false);
  const handleOpen = () => {setOpen(true); dispatch(setReasonText(""))};
  const handleClose = () => {setOpen(false); setPanReasonError(""); dispatch(setReasonText(""))};
  const {reason, panTypeError}= useSelector((state) => state.ExceptionReasonSlice);
  const [panCardErrorMessage,setPanCardErrorMessage]=useState("")
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const message =
    "We could not fetch any details for the PAN and DOB in CKYC records. Kindly fill the correct detail to proceed.";
  const sendThisValueToBackend = useSelector(
    (state) => state.corporateSlice.sendThisValueToBackend
  );
  const kycId = useSelector((state) => state.corporateSlice.kycId);
  const { mi_u, channelUrl, customerType,typeOfUI,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const { phoneNumber } = useSelector((state) => state.userDetails.userData);
  const corporateSliceValue = useSelector((state) => state.corporateSlice);
  const [panErrorReason, setPanErrorReason] = useState(false);
  const [panReasonError, setPanReasonError] = useState("");
  const [kycJourney, setKycJourney] = useState("");

  //date of incorporation format function
  function changedateformat(val) {
    const myArray = val.split("-");
    let year = myArray[0];
    let month = myArray[1];
    let day = myArray[2];
    let formatteddate = day + "-" + month + "-" + year;
    return formatteddate;
  }
  // pan Validator
  const panChangeHandler = (e) => {
    let value = e.target.value.toUpperCase();
    value=value?.replace(/\s/g,"");
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    if (value.length <= 10) {
      setpanOrCkycOrCinValue(value);
      dispatch(coUserDataReducer({ panNumber: value }));
    }
    setpanValidationError(null);

    if (!regexPan.test(value)) {
      setpanValidationError("Invalid Pan Number");
    }
  };

  const gstChangeHandler = (e) => {
    let value = e.target.value.toUpperCase();
    value=value?.replace(/\s/g,"");
    // let regexGST =/^[0-9A-Z]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/i;
    // let regexGST =/^[A-Za-z0-9]{15}/;
    let regexGST =/^[A-Za-z0-9]{15}/;
    if (value.length <= 15) {
      setpanOrCkycOrCinValue(value);
        coGstNumber(value)
    }
    setpanValidationError(null);

    if (!regexGST.test(value)) {
      setGstLength(true)
      setpanValidationError("Invalid GST Number");
    }
  }

  const onHandleChange = (e) => {
    setpanOrCkycOrCinValue(e.target.value);
    dispatch(
      radioValue === "Ckyc"
        ? coCkycNumber(e.target.value)
        : radioValue === "Cin"
        ? coCinNumber(e.target.value)
        : ""
        // : radioValue === "gstNumber"
        // ? coGstNumber(e.target.value)
        // : ""
    );
  };

  let fieldArray = [
    { fieldLength: getCompanyType?.length, fieldName: "Type of Company" },
    {
      fieldLength: panOrCkycOrCinValue?.trim()?.length,
      fieldName:
        radioValue === "Pan"
          ? "Pan"
          : radioValue === "Ckyc"
          ? "CKYC Number"
          : radioValue === "Cin"
          ? "CIN Number" 
          : "GST Number"

    },
    // radioValue === "GST Number" ? {} : 
    {
      fieldLength: dateOfIncorporationValue?.trim()?.length,
      fieldName: "Date of Incorporation",
    },
  ];

  // RADIO BUTTUN HANDLE CHANGE
  const handleChange = (e) => {
    setRadioValue(e.target.value);
    dispatch(coRadioValue(e.target.value));
    setErrorMessage("");
    setpanOrCkycOrCinValue("");
    setpanValidationError("");
  };

  const proprietorRadioValueHandleChange = (e) => {
    setProprietorRadioValue(e.target.value);
    dispatch(coProprietorRadioValue(e.target.value));
  };

  const trustRadioValueHandleChange =(e) => {
    setTrustRadioValue(e.target.value);
    dispatch(coTrustRadioValue(e.target.value));
  }

  const doiOnChangeHandler = (e) => {
    if(e.target.value?.trim()?.length <= 10) {
    setDateOfIncorporationValue(e.target.value);
    dispatch(coUserDoiReducer(changedateformat(e.target.value)));
    }
  };

  const cinHandler = () => {
    const panOrCkycEmptyFieldName = formValidator(fieldArray);
    if (panOrCkycEmptyFieldName) {
      setErrorMessage("Please fill this detail : " + panOrCkycEmptyFieldName);
      scrollIntoViewFunc("errorMsg");
      return;
    }
    let data = JSON.stringify({
      cin: radioValue === "Cin" ? panOrCkycOrCinValue : undefined,
      dob: changedateformat(dateOfIncorporationValue),
      typeOfCompany: sendThisValueToBackend,
      mobile: phoneNumber,
      txnId: mi_u || "",
      channel:channelUrl || "",
      trustType: trustRadioValue,
    });
    if (
      isDeclarationChecked &&
      getCompanyType !== "" &&
      panOrCkycOrCinValue?.trim()?.length > 0 &&
      dateOfIncorporationValue?.trim()?.length > 0
    ) {
      setIsLoading(true);
      coCinService(data)
        .then((response) => {
          dispatch(coPanUserdataReducer(response?.data));
          dispatch(coAddNewKycIdReducer(response?.data?.kyc_id || ""));
          if(!response?.data?.kyc_id && (subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
            setErrorMsg(true)
            return
           }
          navigate("/corporateAddressScreen", {
            state: {
              kyc_ref_id: response?.data?.kyc_id,
              isRouteAllowed: true,
            },
          });
          dispatch(activeStepper(2));
        })
        .catch(function (error) {
          setIsLoading(false);

          if (
            getCompanyType === "Proprietorship" &&
            error?.response?.data?.message === message
          ) {
            dispatch(coErrorMessageReducer(message));
          }
          if (error?.response?.data?.message) {
            if((subChannel==="web" && mi_u.slice(0,3)==="KY3")) {
              setErrorMsg(true)
              return
             }
            navigate("/corporateAddressScreen", {
              state: {
                isRouteAllowed: true,
                isErrorMessage: error?.response?.data?.message,
              },
            });
          }
        });
    }
  };

  const gstHandler= () => {
    const panOrCkycEmptyFieldName = formValidator(fieldArray);
    if (panOrCkycEmptyFieldName) {
      setErrorMessage("Please fill this detail : " + panOrCkycEmptyFieldName);
      scrollIntoViewFunc("errorMsg");
      return;
    }
    let data = JSON.stringify({
      customerType: "C",
      mobile: phoneNumber,
      typeOfCompany: sendThisValueToBackend,
      trustType: trustRadioValue,
      gst: radioValue === "GST Number" ? panOrCkycOrCinValue : undefined,
      doi: changedateformat(dateOfIncorporationValue),
      kyc_id: kycId || "",
      txnId: mi_u || "",
      channel:channelUrl || "",
    });

    if (
      isDeclarationChecked &&
      getCompanyType !== "" &&
      panOrCkycOrCinValue?.trim()?.length > 0 &&
      gstLength
    ) {
      setIsLoading(true);
      coGstService(data)
        .then((response) => {
          let extractedData = response.data;
            console.log("extractedData from PAN",extractedData);
            // DEDUPESD CONCEPT ADDED IF PAN ALREDY EXIST THEN GO OT SUCCESS PAGE 
            if (extractedData?.exists) {
              console.log("deduped workin in PAN")
              //setIsloading(false);
              dispatch(activeStepper(3));
              navigate("/Success", {
                state: {
                  kyc_ref_id: extractedData?.kyc_id,
                  typeOfKyc: extractedData?.status,
                },
              });
            } 
            else {
              console.log("ELSE PAN create new one")
              navigate("/corporateAddressScreen", {
                state: {
                  kyc_ref_id: response?.data?.kyc_id,
                  isRouteAllowed: true,
                  RadioBtnType : ""
                },
              })
          dispatch(coPanUserdataReducer(response?.data));
          dispatch(coAddNewKycIdReducer(response?.data?.kyc_id || ""));
          if(!response?.data?.kyc_id && (subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
            setErrorMsg(true)
            return
           }
          navigate("/corporateAddressScreen", {
            state: {
              kyc_ref_id: response?.data?.kyc_id,
              isRouteAllowed: true,
              RadioBtnType : ""
            },
          });
          dispatch(activeStepper(2));
            }
        })
        .catch(function (error) {
          console.log(error)
          setIsLoading(false);
        });
    }
  }
  const handlePanErrorReasonSubmit =() => {
    console.log("reason value :", reason)
    if(reason.length < 1){
      return setPanReasonError("Please enter a reason")
    }
    setOpen(false);
    if(kycJourney === "ovd"){
      return anotherModePanHandler(false);
    }
    continueHandler(false);
  }
  
  const continueHandler = (isCheckPan) => {
    setKycJourney("pan");
    const panOrCkycEmptyFieldName = formValidator(fieldArray);
    let data = JSON.stringify({
    // let data = {
      trustType: trustRadioValue,
      panNo: radioValue === "Pan" ? panOrCkycOrCinValue : undefined,
      ckycNumber: radioValue === "Ckyc" ? panOrCkycOrCinValue : undefined,
      cin: radioValue === "Cin" ? panOrCkycOrCinValue : undefined,
      // gstNumber: radioValue === "GST Number" ? panOrCkycOrCinValue : undefined,
      dob: radioValue === "GST Number" ? undefined : changedateformat(dateOfIncorporationValue),
      customerType: "C",
      kyc_id: kycId || "",
      txnId: mi_u || "",
      typeOfCompany: sendThisValueToBackend,
      mobile: phoneNumber,
      channel:channelUrl || "",
      doc_properietor_type : sendThisValueToBackend === "properietor" ?  proprietorRadioValue === "Proprietor" ? "Proprietor" : "Proprietorship" : ""

    });
    console.log("data: ",data)
    // };
    if (radioValue === "Cin") {
      return cinHandler();
    }
    if (radioValue === "GST Number") {
      return gstHandler();
    }

    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    if(radioValue === "Pan" && panOrCkycOrCinValue?.trim()?.length > 4 && !regexPan.test(panOrCkycOrCinValue)) {
      scrollIntoViewFunc("errorMsg");
      return;
    }
 
    // let dobORdoi = radioValue === "GST Number" ? true : dateOfIncorporationValue?.trim()?.length > 0
    if (
      isDeclarationChecked &&
      getCompanyType !== "" &&
      radioValue !== "Cin" &&
      panOrCkycOrCinValue?.trim()?.length > 0 &&
      dateOfIncorporationValue?.trim()?.length > 0
      // dobORdoi
    ) {
      var panErrorMessage = checkPanNumber(getCompanyType, panOrCkycOrCinValue)
        if(isCheckPan && radioValue === "Pan" && panOrCkycOrCinValue?.trim()?.length > 4 && panErrorMessage.length){
          dispatch(setPantypeError((panErrorMessage.length > 0) ? true :false))
          setPanCardErrorMessage(panErrorMessage)
          console.log("pan error: ", panTypeError)
          setPanErrorReason(true);
          coIsAdminCheckReason({ phoneNumber: phoneNumber })
          
          .then(function (data) {
            console.log("data=>>>>")
            if(panValidationError){
              return dispatch(
                showGlobalError(
                  getCompanyType === ""
                    ? "Please Select Type of Entity."
                    : panOrCkycOrCinValue.length <= 10
                    ? "Please fill correct PAN."
                    : ""
                )
              );
            }
     
             if (data?.success === true) {
                setPanErrorReason(true);
                  handleOpen();
                }
              else{
                setPanErrorReason(false);
                handleOpen();
              }
            
          }
        )
          
        
         
        }
        else{
          console.log("pan error: ", panTypeError)
      setIsLoading(true);
      coPanService(data, { txnid: mi_u })
      //getPanCardDetailsV2({enc_request: encrypt(data)}, encodeURIComponent(encrypt(`txnid=${mi_u}`)))
          // .then(function (response) {
        .then((response) => {
          //let decData= decrypt(response.enc_response)
            let extractedData = response.data;
         
            console.log("extractedData from PAN",extractedData);
            // DEDUPESD CONCEPT ADDED IF PAN ALREDY EXIST THEN GO OT SUCCESS PAGE 
            if (extractedData?.exists) {
              console.log("deduped workin in PAN")
              //setIsloading(false);
              dispatch(activeStepper(3));
              navigate("/Success", {
                state: {
                  kyc_ref_id: extractedData?.kyc_id,
                  typeOfKyc: extractedData?.status,
                },
              });
            } 
            else {
              console.log("ELSE PAN create new one")
              navigate("/corporateAddressScreen", {
                state: {
                  kyc_ref_id: response?.data?.kyc_id,
                  isRouteAllowed: true,
                },
              })
          dispatch(coPanUserdataReducer(response?.data));
          dispatch(coAddNewKycIdReducer(response?.data?.kyc_id || ""));
          // navigate("/corporateAddressScreen", {
          //   state: {
          //     kyc_ref_id: response?.data?.kyc_id,
          //     isRouteAllowed: true,
          //   },
          // });
          console.log({response})
          if(!response?.data?.kyc_id && (subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
           setErrorMsg(true)
           return
          }
          dispatch(coPanUserdataReducer(response?.data));
          dispatch(coAddNewKycIdReducer(response?.data?.kyc_id || ""));
          setErrorMsg(true)
          navigate("/corporateAddressScreen", {
            state: {
              kyc_ref_id: response?.data?.kyc_id,
              isRouteAllowed: true,
            },
          });
          dispatch(activeStepper(2));
            }
        })
        .catch(function (error) {
          setIsLoading(false);
          if (
            getCompanyType === "Proprietorship" 
          ) {
            dispatch(coErrorMessageReducer(message));
          }
          if( (subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
            setErrorMsg(true)
            return
           }
          if (
            (error?.response?.data?.message !==
            "Mismatch on KYC, Existing KYC being updated with new details") &&
            (error?.response?.data?.message !== "Please provide a valid PAN Number")
          ) {
            navigate("/corporateAddressScreen", {
              state: {
                isRouteAllowed: true,
                isErrorMessage: error?.response?.data?.message,
              },
            });
          }
        }); 
      }
    } else {
      setErrorMessage("Please fill this detail : " + panOrCkycEmptyFieldName);
      scrollIntoViewFunc("errorMsg");
    }
    if (!isDeclarationChecked) {
      scrollIntoViewFunc("declaration");
    }
  };

  const toggleButtonChangeHandler = (e) => {
    dispatch(toggleButton(e.target.checked));
    if (!e.target.checked)
      navigate("/digitalFormSixty", { state: { methodOfKyc: "corporateProprietor" } });
  };

  const anotherModeHandler = () => {
    if (getCompanyType !== "" && radioValue !== "Pan") {
      if((subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
        setErrorMsg(true)
        return
       }
      navigate("/corporateAddressScreen", {
        state: { isRouteAllowed: true, anotherMode: true },
      });
      dispatch(corporateAnotherMode(true));
    } else {
      dispatch(showGlobalError("Please Select Type of Entity."));
    }
  };
  const anotherModePanHandler2=async()=>{
    setKycJourney("ovd");
    await anotherModePanHandler(true);
  }
  const anotherModePanHandler = async(isCheckPan) => {
    let data = JSON.stringify({
      // let data = {
        trustType: trustRadioValue,
        panNo: radioValue === "Pan" ? panOrCkycOrCinValue : undefined,
        ckycNumber: radioValue === "Ckyc" ? panOrCkycOrCinValue : undefined,
        cin: radioValue === "Cin" ? panOrCkycOrCinValue : undefined,
        // gstNumber: radioValue === "GST Number" ? panOrCkycOrCinValue : undefined,
        dob: radioValue === "GST Number" ? undefined : changedateformat(dateOfIncorporationValue),
        customerType: "C",
        kyc_id: kycId || "",
        doc_type:"OVD",
        txnId: mi_u || "",
        typeOfCompany: sendThisValueToBackend,
        mobile: phoneNumber,
        channel:channelUrl || "",
        doc_type:"OVD"
      });

    if((subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
      setErrorMsg(true)
      return
     }
    let panErrorMessage = checkPanNumber(getCompanyType, panOrCkycOrCinValue);
    if(isCheckPan && radioValue === "Pan" && panOrCkycOrCinValue?.trim()?.length > 4 && panErrorMessage.length){
      dispatch(setPantypeError((panErrorMessage.length > 0) ? true :false))
      setPanCardErrorMessage(panErrorMessage)
      console.log("pan error: ", panTypeError)
      setPanErrorReason(true);
      coIsAdminCheckReason({ phoneNumber: phoneNumber })
      
      .then(function (data) {
        console.log("data=>>>>")
        if(panValidationError){
          return dispatch(
            showGlobalError(
              getCompanyType === ""
                ? "Please Select Type of Entity."
                : panOrCkycOrCinValue.length <= 10
                ? "Please fill correct PAN."
                : ""
            )
          );
        }
 
         if (data?.success === true && !open) {
            setPanErrorReason(true);
              handleOpen();
            }
          else if(!open){
            setPanErrorReason(false);
            handleOpen();
          }
        
      }
    )
      
    
     
    } else{
      if (
        getCompanyType !== "" &&
        panValidationError !== "Invalid Pan Number" &&
        panOrCkycOrCinValue.length >= 10
      ) {
        
        coPanService(data, { txnid: mi_u })
        //getPanCardDetailsV2({enc_request: encrypt(data)}, encodeURIComponent(encrypt(`txnid=${mi_u}`)))
            // .then(function (response) {
          .then((response) => {
            //let decData= decrypt(response.enc_response)
            // data.doc_type="OVD";
              let extractedData = response.data;
              // console.log("extractedData from PAN",extractedData);
              let doc_type= extractedData.doc_type="OVD"
              let updatedExtractedData = {...extractedData , doc_type}
              console.log("updatedExtractedData from PAN 11",updatedExtractedData);

              // DEDUPESD CONCEPT ADDED IF PAN ALREDY EXIST THEN GO OT SUCCESS PAGE 
              if (updatedExtractedData?.exists) {
                console.log("deduped workin in PAN")
                //setIsloading(false);
                dispatch(activeStepper(3));
                navigate("/Success", {
                  state: {
                    kyc_ref_id: extractedData?.kyc_id,
                    typeOfKyc: extractedData?.status,
                    doc_type:"OVD",
                  },
                });
              } 
              else {
                console.log("ELSE PAN create new one")
                navigate("/corporateAddressScreen", {
                  state: { isRouteAllowed: true, anotherMode: true, doc_type:"OVD" },
                })
                // navigate("/corporateAddressScreen", {
                //   state: { isRouteAllowed: true, anotherMode: true },
                // });
                dispatch(corporateAnotherMode(true));
              }
            }).catch((e)=>{
              console.log("ELSE PAN create new one")
              navigate("/corporateAddressScreen", {
                state: { isRouteAllowed: true, anotherMode: true, doc_type:"OVD" },
              })
              // navigate("/corporateAddressScreen", {
              //   state: { isRouteAllowed: true, anotherMode: true },
              // });
              dispatch(corporateAnotherMode(true));
            })
      } else {
        dispatch(
          showGlobalError(
            getCompanyType === ""
              ? "Please Select Type of Entity."
              : panOrCkycOrCinValue.length <= 10
              ? "Please fill correct PAN."
              : ""
          )
        );
      }
    }
  };

  useEffect(() => {
    dispatch(activeStepper(0));
    console.log({getCompanyType})
    if (getCompanyType === "Proprietorship") {
      dispatch(coProprietorRadioValue("Proprietor"));
    }
  }, [getCompanyType]);

  useEffect(() => {
  if (getCompanyType === "Trust") {
    dispatch(coTrustRadioValue("Trust"));
  }
}, [getCompanyType]);

  useEffect(() => {
    if (getCompanyType === "Foreign Embassy") {
      if( (subChannel==="web" && mi_u.slice(0,3)==="KY3") ) {
        setErrorMsg(true)
        return
       }
      navigate("/corporateAddressScreen", { state: { isRouteAllowed: true } });
    }
  }, [getCompanyType, navigate]);

  useEffect(() => {
    if (channelUrl || customerType === "C") dispatch(clearState());
  }, [channelUrl]);
  useEffect(() => {
    // disable browser button
    return () => {
      if (channelUrl)
        setTimeout(() => {
          window.history.forward();
        }, 0);
    };
  }, [channelUrl]);

  // useEffect(() => {
  //   //empty redux value
  //   if (getCompanyType !== "Proprietorship")
  //     dispatch(coProprietorRadioValue(""));
  // }, [getCompanyType]);
  useEffect(() => {
    //empty redux value
    if (getCompanyType === "Proprietorship"){
      dispatch(coProprietorRadioValue(proprietorRadioValue));
    }
  }, [getCompanyType]);

  useEffect(() => {
  if (getCompanyType !== "Trust")
      dispatch(coTrustRadioValue(""));
  }, [getCompanyType]);

  useEffect(()=>{
    dispatch(coRadioValue("Pan"))
  },[])

  useEffect(()=>{
    // console.log("radioValue1",corporateSliceValue);
    if(corporateSliceValue?.companyType === "Proprietorship"){
      dispatch(coProprietorRadioValue(proprietorRadioValue));
    }
  },[corporateSliceValue]);

  const [capthaValidationErr, setCapthaValidationErr] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [captchaVal, setCaptchaVal] = useState(null);
   const [captchaErrDrawer, setCaptchaErrDrawer] = useState(false)

   useEffect(()=>{generateCaptcha()},[])

   function generateCaptcha () {
     getCaptcha().then((data)=>{
       let decResponse= decrypt(data.enc_response)
       sessionStorage.setItem('sessionID', decResponse?.capthchaSessionID);
       let svgString = decResponse?.captchaSvg?.replace(/\\/g, '');
       const svgDataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`; 
       setImgUrl(svgDataUrl)
     })
   }
    
   function captchaChangeHandler(value) {
   value=value?.replace(/\s/g,"");
    if (value.length <= 6) {
     setCaptchaVal(value);
   }
   }

   function regeneratCaptcha(e) {
     e.preventDefault()
     generateCaptcha()
     setCaptchaVal("")
     setCapthaValidationErr("")
   }

   const captchaHandler = (btnType) => { 
     if(captchaVal?.length === 6) {
       console.log(captchaVal)
       const storedValue = sessionStorage.getItem('sessionID');
       if(captchaVal === storedValue ) {

         btnType === 'continueHandler' 
                      ? continueHandler(true) 
                      : radioValue === "Pan"
                      ? anotherModePanHandler2()
                      : anotherModeHandler()

         sessionStorage.removeItem('sessionID');

       } else {
         setCaptchaErrDrawer(true)
         generateCaptcha() 
         setCaptchaVal("")
         setCapthaValidationErr("")
       }
   } else {
    let errmsg =  btnType === 'continueHandler' ? 'Continue' : 'use another mode'
     setCaptchaError(true)
     scrollIntoViewFunc("captcha_number");
     setCapthaValidationErr(`Please enter valid captcha to ${errmsg}.`)
   } }

  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <div className="h-full flex flex-col" id="coMainDiv">
          <MsHome>
            <BannerImage>
              <LogoContainer />
            </BannerImage>
            <div className="bg-[#FCE9E9] h-full">
              <BottomCard max>
                <div
                  className="md:w-[100%] md:h-[90%] overflow-scroll  mt-0 md:mt-0"
                  id="coInputDiv"
                >
                  <div className=" overflow-scroll ">
                    <CommonDropDown
                      width={"md:w-[300px]"}
                      selectedValueinRedux={dispatch(
                        corporateDropDownValue(getCompanyType)
                      )}
                      options={(subChannel==="web" && mi_u.slice(0,3)==="KY3") ? companyTypeMaruti : companyType}
                      selected={getCompanyType}
                      label="Type of Entity"
                      setSelected={(value) => {
                        setGetCompanyType(value);
                        dispatch(corporateCompanyType(value))
                        // eslint-disable-next-line array-callback-return
                        storeValueMatcher.map((item) => {
                          if (value === item.label) {
                            dispatch(corporateBackendValue(item.value));
                          }
                        });
                      }}
                    />
                    {getCompanyType === "Proprietorship" && (
                      <div>
                        <label>Fetch CKYC details for:</label>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={proprietorRadioValue}
                          onChange={proprietorRadioValueHandleChange}
                        >
                          <FormControlLabel
                            value="Proprietor"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "#E42125",
                                    },
                                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                    color: "#E42125",
                                  },
                                }}
                              />
                            }
                            label="Proprietor"
                          />
                          <FormControlLabel
                            value="Proprietorship"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "#E42125",
                                    },
                                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                    color: "#E42125",
                                  },
                                }}
                              />
                            }
                            label="Proprietorship"
                          />
                        </RadioGroup>
                       
                      </div>
                    )}
{/* RADIO BUTTON START*/}

{/* Checkbox Options for Trust Company Type */}

                {getCompanyType === "Trust" && (
                      <div>
                        <label>Trust Type:</label>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={trustRadioValue}
                          onChange={trustRadioValueHandleChange}
                        >
                          <FormControlLabel
                            value="Constituted For Religious"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "#E42125",
                                    },
                                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                    color: "#E42125",
                                  },
                                }}
                              />
                            }
                            label="Constituted For Religious"
                          />
                          <FormControlLabel
                            value="Charitable Purposes"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "#E42125",
                                    },
                                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                    color: "#E42125",
                                  },
                                }}
                              />
                            }
                            label="Charitable Purposes"
                          />
                          <FormControlLabel
                            value="Others"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "#E42125",
                                    },
                                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                    color: "#E42125",
                                  },
                                }}
                              />
                            }
                            label="Others"
                          />
                        </RadioGroup>
                       
                      </div>
                    )}


{ getCompanyType === "Proprietorship" && proprietorRadioValue==="Proprietor" &&
 <div className="flex pb-2 pt-2 gap-[10px]  items-center max-w-[100%]">
 <p>Do you have a valid PAN Number?</p>
 <ToggleButton
   onChange={(e) => toggleButtonChangeHandler(e)}
   checked={istoggle}
 />
</div> }

<label>Using:</label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={radioValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Pan"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                {
                                  color: "#E42125",
                                },
                              "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                color: "#E42125",
                              },
                            }}
                          />
                        }
                        label="PAN"
                      />
                      <FormControlLabel
                        value="Ckyc"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                {
                                  color: "#E42125",
                                },
                              "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                color: "#E42125",
                              },
                            }}
                          />
                        }
                        label="CKYC Number"
                      />
                      {/* {getCompanyType === "Proprietorship" ? (
                        ""
                      ) : (
                        <FormControlLabel
                          value="Cin"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                  {
                                    color: "#E42125",
                                  },
                                "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                  color: "#E42125",
                                },
                              }}
                            />
                          }
                          label="CIN"
                        />
                      )} */}
                      <FormControlLabel
                        value="GST Number"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                {
                                  color: "#E42125",
                                },
                              "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                color: "#E42125",
                              },
                            }}
                          />
                        }
                        label="GST Number"
                      />
                    </RadioGroup>
{/* RADIO BUTTON END*/}

{/* TEXT FIELDS START */}
                    <TextInput
                      label={
                        radioValue === "Pan"
                          ? getCompanyType === "Proprietorship"
                          ? `PAN No. of ${proprietorRadioValue}`
                          : "PAN No. of Entity"
                          : radioValue === "Ckyc"
                          ? "CKYC Number"
                          : radioValue === "Cin"
                          ? "CIN Number" 
                          : "GST No. of Entity"
                      }
                      width="md:w-[300px]"
                      placeholder={
                        radioValue === "Pan"
                          ? "Ex. AIYPM1234J"
                          : radioValue === "Ckyc"
                          ? "CKYC Number"
                          : radioValue === "Cin"
                          ? "CIN Number" 
                          : "Ex. 22AAAAA0000A1Z5"
                      }
                      value={panOrCkycOrCinValue}
                      onChange={
                        radioValue === "Pan" ? panChangeHandler : radioValue === "GST Number" ? gstChangeHandler :  onHandleChange
                      }
                      success={
                        panValidationError || panOrCkycOrCinValue?.length <= 0
                          ? false
                          : true
                      }
                    />
                    {panValidationError && (
                      <p className="text-red-500 text-sm">
                        {panValidationError}
                      </p>
                    )}
                    <TextInput
                      label={
                        "Date of Incorporation"
                        // proprietorRadioValue === "Proprietor"
                        //   ? "Date of Birth"
                        //   : "Date of Incorporation"
                      }
                      width="md:w-[300px]"
                      type="date"
                      value={dateOfIncorporationValue}
                      onChange={doiOnChangeHandler}
                    />
{/* TEXT FIELDS END */}

                    <p className="text-red-500 text-sm" id="errorMsg">
                      {errorMessage}
                    </p>
                  </div>
                  <div className="flex- mt-2 md:mt-0" id="declaration">
                    <input
                      id="pan_dec_checkbox"
                      type="checkbox"
                      value={isDeclarationChecked}
                      checked={isDeclarationChecked}
                      className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                      onChange={(e) =>
                        setIsDeclarationChecked(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="link-checkbox"
                      className="ml-2 text-sm font-medium text-black "
                    >
                      I hereby provide my consent to HDFC ERGO General Insurance
                      Company Limited to retrieve/verify my/our KYC documents
                      like Address/ID proof and photograph from the CERSAI
                      portal against my PAN /CKYC No./CIN No. I understand that
                      the said KYC documents shall be relied upon by the company
                      for processing my policy/refunds/Claims.
                    </label>
                  </div>
               
{/* //CAPTCHA CODE START */}
                      <div className="captcha-div" style={{ display: "flex" , marginTop:"15px"}}>
                        <img
                          src={imgUrl}
                          style={{ border:"1px solid #d1d5db", padding :"5px", borderRadius: "10px", marginRight: "12px" }}
                          alt="Captcha Img"
                        />
                        <Tooltip
                        style={{margin:"20px 15px 0px 0px"}}
                 id="button-report1"
                 title={"Captcha is mandate from Infosec point of view"}
               >
                 <InfoIcon sx={{ color: "black", fontSize: "17px" }} />
               </Tooltip>
                         <SyncOutlinedIcon sx={{ color: "black", 
                         fontSize: "25px", marginTop:"15px",
                         marginRight:"10px" , cursor:"pointer" }} onClick={(e)=>regeneratCaptcha(e)} />
                        <TextInput
                          placeholder={"Enter Captcha"}
                          // width="md:w-[300px]"
                          value={captchaVal}
                          onChange={(e) => captchaChangeHandler(e.target.value)}
                          success={captchaVal <= 0 ? false : true}
                         //  id="captcha_number"
                          required
                        />
                              </div>
                         {captchaError && (
                            <p className="text-red-500 text-sm" style={{marginTop:"5px"}}>
                              {capthaValidationErr}
                            </p>
                          )}
                          
                          <div id="captcha_number" style={{margin:"20px"}}></div>
{/* //CAPTCHA CODE END */}

                </div>
                <div className="md:w-[300px]">
                  <CommonButton
                    label="Continue"
                    filled
                    arrow
                    // onClickHandler={()=>continueHandler(true)}
                    onClickHandler={()=>captchaHandler('continueHandler')}
                  />
                </div>
                {!(subChannel==="web" && mi_u.slice(0,3)==="KY3") && (!(getCompanyType === "Proprietorship" &&  proprietorRadioValue==="Proprietor") &&
                <p
                  className="text-center md:text-left underline text-blue-500 cursor-pointer mt-2"
                  // onClick={
                  //   radioValue === "Pan"
                  //     ? anotherModePanHandler2
                  //     : anotherModeHandler
                  // }
                  onClick={()=>captchaHandler('anotherModeHandler')}
                >
                  Use another mode to submit KYC
                </p>)
}
              </BottomCard>
            </div>
           {errorMsg && 
              <ErrorBottomDrawer
                        info={false}
                        errorMessage={"Data Not Found"}
                        onClickHandler={() => setErrorMsg(false)}
                      />}
        {captchaErrDrawer && (
            <ErrorBottomDrawer
              extraNotes = "Error"
              errorMessage="CAPTCHA verification failed!"
              onClickHandler={() => setCaptchaErrDrawer(false)}
            />
          )}
          </MsHome>
        </div>
      )}
      { panErrorReason ?
      <CommonTemplate isTemplateOpen={open} backTemplate={handleClose} closeTemplate={handleClose} submitTemplate={handlePanErrorReasonSubmit} modalLabel={"Pan card type error"} modalText={panCardErrorMessage} key="new" >
        <TextInput placeholder={"Enter The reason"} onChange={(e) => { dispatch(setReasonText(e.target.value)) }} value={reason} maxlen="300"/>
        {panReasonError && reason.length<1 && <p className="text-red-700 text-sm">{panReasonError}</p>}
      </CommonTemplate>
      : 
      <CommonTemplate isTemplateOpen={open}  oKTemplate={handleClose} closeTemplate={handleClose} modalLabel={"Pan card type error"}  key="new1" userDetails={phoneNumber} >
        <p>You have selected the entity type as "{getCompanyType}" but PAN entered is not of "{getCompanyType}" entity. If this is not an error and you still want to proceed with the same PAN, Please connect with <a style={{cursor:"pointer",color:"blue"}}>Priyanka.Shinde@hdfcergo.com </a>for any exception cases.</p>
      </CommonTemplate>
      }
    </>
  );
};

export default CorporatePanCardLanding;
